import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import IdField from '@/shared/fields/id-field';
import EnumField from '@/shared/fields/enum-field';
import DateField from '@/shared/fields/date-field';
import StringField from '@/shared/fields/string-field';
import ComputedField from '@/shared/fields/computed-field';
import { ApplicationTypeField } from '@/modules/applications/fields/application-type-field';
import BooleanField from '@/shared/fields/boolean-field';
import { TerritorialAdministrativeUnitField } from '@/shared/common-fields/territorial-administrative-unit-field';

const label = (name) => i18n.t(`APPLICATION.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`APPLICATION.PLACEHOLDERS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`APPLICATION.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const STATUSES = [
    {
        value: 'draft',
        name: 'draft',
        text: enumLabel('status', 'draft'),
        type: 'dark',
        sortkey: 0
    },
    {
        value: 'registered',
        name: 'registered',
        text: enumLabel('status', 'registered'),
        type: 'dark',
        sortkey: 0
    },
    {
        value: 'new',
        name: 'new',
        text: enumLabel('status', 'new'),
        type: 'primary',
        sortkey: 1
    },
    {
        value: 'pending',
        name: 'pending',
        text: enumLabel('status', 'pending'),
        type: 'warning',
        sortkey: 2
    },
    {
        value: 'review',
        name: 'review',
        text: enumLabel('status', 'review'),
        type: 'warning',
        sortkey: 3
    },
    {
        value: 'approved',
        name: 'approved',
        text: enumLabel('status', 'approved'),
        type: 'warning',
        internal: true,
        sortkey: 4
    },
    {
        value: 'sign_pending',
        name: 'sign_pending',
        text: enumLabel('status', 'sign_pending'),
        type: 'warning',
        internal: true,
        sortkey: 5
    },
    {
        value: 'sign_applicant_user_pending',
        name: 'sign_applicant_user_pending',
        text: enumLabel('status', 'sign_applicant_user_pending'),
        type: 'warning',
        sortkey: 6
    },
    {
        value: 'signed',
        name: 'signed',
        text: enumLabel('status', 'signed'),
        type: 'warning',
        internal: true,
        sortkey: 7,
        display: true
    },
    {
        value: 'rejected_in_progress',
        name: 'rejected_in_progress',
        text: enumLabel('status', 'rejected_in_progress'),
        internal: true,
        type: 'warning',
        sortkey: 8,
    },
    {
        value: 'rejected',
        name: 'rejected',
        text: enumLabel('status', 'rejected'),
        type: 'danger',
        sortkey: 9,
    },
    {
        value: 'closed',
        name: 'closed',
        text: enumLabel('status', 'closed'),
        type: 'success',
        sortkey: 10
    },
];

const fields = {
    id: new IdField('id', 'Id'),
    categoryType: new IdField('categoryType', 'Categorie'),
    identification_number: new StringField('identification_number', label('identification_number'), {},
        {
            placeholder: placeholder('identification_number'),
            primary: false,
        }),
    parent_application_identification_number: new StringField('parent_application_identification_number', label('parent_application_identification_number'), {}),
    quick_search_values: new StringField('quick_search_values', label('quick_search_values'), { type: "quicksearch" },
        {
            placeholder: placeholder('quick_search_values'),
            primary: true,
        }),
    application_type_name: new StringField('application_type_name', label('application_type_name_column'),{},
        {
            placeholder: placeholder('application_type_name'),
            primary: false,
        }),
    application_type_id: ApplicationTypeField.relationToOne(
        'application_type_id',
        label('application_type_name'),
        { autocompleteRequired: true },
        { placeholder: placeholder('application_type_name')},
        [],
        { asyncData: true, hasPagination: true }
    ),
    document_name: ApplicationTypeField.relationToOne(
        'application_type_id',
        label('application_type_name'),
        { autocompleteRequired: true },
        {
            placeholder: placeholder('application_type_name'),
            width: 'md',
            primary: true,
        },
        [],
        { asyncData: true, hasPagination: true }
    ),
    status: new EnumField(
        'status',
        label('status'),
        [],
        {},
        { placeholder: placeholder('status')}
    ),
    internal_status: new EnumField(
        'internal_status',
        label('status'),
        [],
        {},
        { placeholder: placeholder('status')}
    ),
    created_date: new DateField('created_date', label('created_date'),{},{primary: false,}),
    last_review_date: new DateField('last_review_date', label('last_review_date'),{},{primary: false, filter_label: label('last_review_date')}),
    application_date: new DateField('application_date', label('application_date'),{},{primary: false, filter_label: label('application_date')}),
    issued_date: new DateField('issued_date', label('issued_date'),{},{primary: false,}),
    response_due_in_days: new ComputedField('response_due_in_days', label('response_due_in'),
    (record) => {
        if (!record || record.response_due_in_days === undefined) return '';
        return `${record.response_due_in_days} ${i18n.tc('GENERAL.DAYS', Number(record.response_due_in_days))}`
    }),
    pin: new StringField('pin', label('pin'), { required: true, min: 6 }),
    assigned_staff_user_name: new ComputedField('assigned_staff_user_name', label('assigned_to'),
        ({assigned_staff_user_name}) => {
            if (!assigned_staff_user_name) return i18n.t('APPLICATION.LIST.NO_ASSIGNEE');
            return assigned_staff_user_name;
        },
        {},
        {
            placeholder: placeholder('assigned_to'),
            primary: false,
        }
    ),
    contact_person: new ComputedField('contact_person', label('contact_person'),
        (record) => {
            if (!record.form?.applicant_user?.is_contact_person && record.applicant_user) {
                return `${record.applicant_user.first_name} ${record.applicant_user.last_name} `;
            }
            return `${record.form.applicant_user.first_name} ${record.form.applicant_user.last_name} `;
        }
    ),
    created_by: new ComputedField('created_by', label('created_by'),
        (record) => {
            if (!record.applicant_user?.first_name)  return '';
            return `${record.applicant_user.first_name} ${record.applicant_user.last_name} `;
        }
    ),
    comment: new StringField('comment', label('clarification_comment'), { required: true }),
    message_comment: new StringField('message_comment', label('message_comment'), { required: true }),
    beneficiary: new StringField('form.beneficiary_users.full_name', label('beneficiary'),{},
    {
        placeholder: placeholder('beneficiary'),
        primary: true,
    }),
    beneficiary_legal: new StringField('form.beneficiary_users.company_name', label('beneficiary_legal'),{},
    {
        placeholder: placeholder('beneficiary_legal'),
        primary: true,
    }),
    locality: new StringField('locality', label('locality'),{} ,
    {
        placeholder: placeholder('locality'),
    }),
    emplacement_locality: new StringField('locality', label('emplacement_locality')),
    is_paid: new BooleanField('is_paid',label('is_paid')),
    is_signed: new BooleanField('is_signed',label('is_signed')),
    cadastral_number:new StringField('form.real_estate_target.cadastral_number', label('cadastral_number'),{} ,
    {
        placeholder: placeholder('cadastral_number'),
    }),
    total_tax:  new ComputedField('total_tax', label('total_tax'),
        (record) => {
            return record.tax_summary?.total ?? '0';
        }
    ),
    payment_type:  new ComputedField('payment_details', label('payment_type'),
    (record) => {
        return record.payment_details?.payment_type ? i18n.t('APPLICATION.FIELDS.PAYMENT_TYPE_'+ record.payment_details?.payment_type.toUpperCase()) : '';
    }
    ),
    allocated_document_series: new StringField('allocated_document_series', label('allocated_document_series'), {},
    {
        placeholder: placeholder('allocated_document_series'),
    }),
    // Filters
    territorial_administrative_unit_id: TerritorialAdministrativeUnitField.relationToOne(
        'form.real_estate_target.territorial_administrative_unit_id',
        label('territorial_administrative_unit'),
        { autocompleteRequired: true },
        {
            placeholder: placeholder('territorial_administrative_unit') ,
            type: 'autocomplete',
            primary: false,
        },
    ),
    comments: new StringField('comments', label('comments'), { required: true },
    {
        placeholder: placeholder('comments'),
    }),
    selected: new BooleanField('selected'),
    first_beneficiary_pnc:  new ComputedField('first_beneficiary_cnp', label('personal_numeric_code'),
        (record) => {
            return record.form?.beneficiary_users?.[0].personal_numeric_code ?? '';
        }
    ),
    citizen_review: new StringField('citizen_review', label('citizen_review'), { required: true },
    {
        placeholder: placeholder('citizen_review'),
    }),
    transaction_number:  new ComputedField('transaction_number', label('transaction_number'),
    (record) => record.transaction_number ?? '')
};

export class ApplicationModel extends GenericModel {
    static get fields() {
        return fields;
    }
    static get statuses() {
        return STATUSES;
    }
}
