<template>
    <div>
        <st-page
            :title="title"
            layout="tab"
            :backLink="backLink"
        >
            <template #additional-title>
                <st-button
                    v-can:read="'applicationsStaff'"
                    variant="custom-link"
                    class="pl-12 st-btn-text-primary"
                    :callback="redirect"
                    v-if="parentApplicationIdentificationNumber"
                >
                    <i class="fa fa-file"></i>
                    {{ $t("INTERNAL_DOCUMENTS.VIEW_APPLICATION_BUTTON", { number: parentApplicationIdentificationNumber}) }}
                </st-button>
            </template>
            <template #toolbar>
                <internal-documents-view-toolbar v-if="showToolbar"></internal-documents-view-toolbar>
            </template>
            <b-tabs class="st-tabs" justified lazy v-model="activeTab">
                <b-tab v-for="tab in tabs" :key="tab.index" :disabled="tab.disabled">
                    <template #title>
                        <div class="d-flex justify-content-center w-100">
                            <i :class="['fas', tab.icon]"></i>
                            <span class="text-uppercase ml-4">{{ tab.titleKey }}</span>
                        </div>
                    </template>
                    <component
                        v-if="!isLoading"
                        :is="tab.componentType"
                        :internal-document="true"
                        :record="record"
                    >
                    </component>
                </b-tab>
            </b-tabs>
        </st-page>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InternalDocumentsApplicationDetails from '@/modules/internal-documents/components/application-details/InternalDocumentsApplicationDetails.vue';
import InternalDocumentsDetails from '@/modules/internal-documents/components/details/InternalDocumentsDetails.vue';
import DocumentsList from '@/modules/internal-documents/components/documents/DocumentsList.vue';
import InternalDocumentsActivityTab from '@/modules/internal-documents/components/activities/InternalDocumentsActivityTab.vue';
import InternalDocumentsViewToolbar from '@/modules/internal-documents/components/InternalDocumentsViewToolbar.vue';
import ApplicationRegisterTab from '@/modules/internal-documents/components/ApplicationRegisterTab.vue';

export default {
    name: 'InternalDocumentForm',
    components: {
        InternalDocumentsApplicationDetails,
        DocumentsList,
        InternalDocumentsActivityTab,
        InternalDocumentsDetails,
        InternalDocumentsViewToolbar,
        ApplicationRegisterTab,
    },
    props: {
        id: String,
    },
    data() {
        return {
            disableTab: [
                false,
                true,
                true,
                true
            ],
            activeTab: 0
        };
    },
    computed: {
        ...mapGetters({
            record: 'internalDocuments/form/record',
            loading: 'shared/loading',
        }),
        backLink() {
            return  { name: 'applications' } ;
        },
        title() {
            if (!this.id) return this.$t('INTERNAL_DOCUMENTS.FORM.NEW_DOCUMENT');

            let identification = '';
            if (this.record?.identification_number) {
                identification = this.record.identification_number;
            }

            if (this.record?.allocated_document_series) {
                identification = this.record.allocated_document_series;
            }

            if (identification) {
                return this.$t('INTERNAL_DOCUMENTS.FORM.EDIT_TITLE', [identification]);
            } 
                
            return this.record?.application_type_name;
        },
        tabs() {
            let tabs = [
                {
                    index: 0,
                    name: 'applicationDetails',
                    titleKey: this.$t('INTERNAL_DOCUMENTS.TABS.APPLICATION_DETAILS'),
                    icon: 'fa-edit',
                    componentType: 'InternalDocumentsApplicationDetails',
                    disabled: this.disableTab[0],
                    toBeRemoved: true
                },
                {
                    index: 1,
                    name: 'documentDetails',
                    icon: 'fa-edit',
                    titleKey: this.$t('INTERNAL_DOCUMENTS.TABS.DOCUMENT_DETAILS'),
                    componentType: 'InternalDocumentsDetails',
                    disabled: this.disableTab[1],
                },
                {
                    index: 2,
                    name: 'registerDetails',
                    icon: 'fa-edit',
                    titleKey: this.$t("APPLICATION.TABS.REGISTRY_DETAILS"),
                    componentType: 'ApplicationRegisterTab',
                    disabled: this.disableTab[2],
                },
                {
                    index: 3,
                    name: 'documents',
                    icon: 'fa-file',
                    titleKey: this.$t('INTERNAL_DOCUMENTS.TABS.DOCUMENTS'),
                    componentType: 'DocumentsList',
                    disabled: this.disableTab[3],
                },
                {
                    index: 4,
                    name: 'activity',
                    icon: 'fa-list',
                    titleKey: this.$t('INTERNAL_DOCUMENTS.TABS.ACTIVITIES'),
                    componentType: 'InternalDocumentsActivityTab',
                    disabled: this.disableTab[4],
                },
            ];

            if (this.id && !this.record?.has_internal_input_doc) {
                tabs = tabs.filter((el) => !el.toBeRemoved);
            }

            return tabs;
        },
        isLoading() {
            return this.loading['internalDocument/find'];
        },
        showToolbar() {
            return !!this.record && !['closed', 'draft'].includes(this.record?.internal_status);
        },
        parentApplicationIdentificationNumber() {
            return this.record?.parent_application_identification_number;
        },
    },
    methods: {
        ...mapActions({
            doFindRecord: 'internalDocuments/form/find',
            initDefaultState: 'internalDocuments/form/initFormState',
        }),
        redirect() {
            this.$router.push({ name: 'applicationView', params: { id: this.record.parent_application_id }})
        },
    },
    async created() {
         if (this.id && !this.record) {
            try {
                await this.doFindRecord(this.id);
            } catch {
                return await this.$router.push({
                    name: 'internal-documents'
                });
            }
            
        }
    },
    mounted() {
         if (this.id) {
            this.disableTab = this.disableTab.map(() => false);
        }
    },
    updated() {
        const tabDisabled = this.disableTab.find(tab => tab === true);
        if (this.id && tabDisabled) {
            this.disableTab = this.disableTab.map(() => false);
        }
    },
    beforeDestroy() {
        this.initDefaultState();
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit('shared/setModuleName', null);
        next();
    }
}
</script>
